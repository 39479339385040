import objectPath from 'object-path';
import findPathDeep from 'deepdash/findPathDeep';

const handleTranslate = (translates, template) => {
  if (!translates) {
    return template;
  }

  try {
    translates.forEach((item) => {
      const { key, value } = item;

      const match = findPathDeep(template, (value) => `${value}`.indexOf(key) !== -1);

      if (match) {
        const source = objectPath.get(template, match);

        if (source) {
          objectPath.set(template, match, source.replace(key, value));
        }
      }
    });

    return template;
  } catch (error) {
    return template;
  }
};

export default handleTranslate;
