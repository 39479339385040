import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MobileDetect from 'mobile-detect';
import { useTranslate } from 'react-translate';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import { DataGridPremium, ukUA } from '@mui/x-data-grid-premium';
import { LicenseInfo } from '@mui/x-license-pro';
import LinearProgress from '@mui/material/LinearProgress';
import config from 'config';
import storage from 'helpers/storage';
import Toolbar from './components/Toolbar';
import CustomColumnMenu from './components/CellMenu';
import CustomPagination from './components/Pagination';

LicenseInfo.setLicenseKey(config?.muiLicenseKey);

const useStyles = makeStyles((theme) => ({
  highlightedRow: {
    backgroundColor: theme.dataTableHighlights
  },
  clickableRow: {
    cursor: 'pointer'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  toolbarActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 3,
    '& > *': {
      marginRight: theme.spacing(1)
    },
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      gap: theme.spacing(1),
      '& > *': {
        marginRight: 0
      },
      '& > .MuiInputBase-root': {
        flex: 1
      }
    }
  },
  toolbarQuickFilter: {
    backgroundColor: theme.leftSidebarBg,
    borderRadius: 40,
    padding: 0,
    minWidth: 320,
    '& fieldset': {
      transition: 'border-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      borderColor: 'transparent'
    },
    '& .MuiInputBase-root': {
      height: 40,
      borderRadius: 40,
      '&:hover': {
        '& fieldset': {
          border: '2px solid #0068FF'
        }
      }
    },
    '& .Mui-focused fieldset': {
      border: '2px solid #0068FF'
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: 'unset',
      width: '100%'
    }
  },
  buttonSm: {
    [theme.breakpoints.down('sm')]: {
      ...(theme.filtersButtonMobile || {})
    }
  },
  columnOutline: {
    '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
      outline: 'none!important'
    }
  },
  withRowCount: {
    '& .MuiDataGrid-main': {
      flex: 'none'
    }
  },
  rowCount: {
    padding: '15px 10px',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    borderTop: '1px solid #e0e0e0',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
}));

const COLUMN_WIDTHS_STORAGE_KEY = 'dataGrid.columnWidths';
const COLUMN_VISIBILITY_STORAGE_KEY = 'dataGrid.columnVisibility';

const DataGrid = (props) => {
  const {
    rows = [],
    columns = [],
    highlight = [],
    loading,
    onRowClick,
    actions,
    search: searchProps,
    rowsPerPage: pageSize,
    checkable,
    controls,
    count,
    page,
    sort,
    keepNonExistentRowsSelected,
    CustomToolbar,
    filters,
    rowsSelected,
    filterHandlers,
    unstableHeaderFilters,
    startPage,
    columnVisibilityModel: columnVisibilityModelProps,
    pagination,
    showRowCount,
    getRowId,
    height,
    onColumnVisibilityCallback,
    hiddenMenu,
    previewAttach,
    localeText
  } = props;

  const [isMobile] = React.useState(() => {
    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = typeof props.isMobile === 'boolean' ? props.isMobile : !!md.mobile();
    return isMobile;
  });
  const timeoutRef = React.useRef(null);
  const dataGridRef = React.useRef(null);
  const [search, setSearch] = React.useState(searchProps);
  const [rowCountState, setRowCountState] = React.useState(count);
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(() => {
    return {
      ...JSON.parse(storage.getItem(COLUMN_VISIBILITY_STORAGE_KEY) || '{}'),
      ...columnVisibilityModelProps
    };
  });
  const [sortModel, setSortModel] = React.useState(() => {
    return Object.keys(sort).map((field) => ({
      field,
      sort: sort[field]
    }));
  });
  const [columnWidths, setColumnWidth] = React.useState(() => {
    return JSON.parse(storage.getItem(COLUMN_WIDTHS_STORAGE_KEY) || '{}');
  });

  const classes = useStyles();
  const t = useTranslate('Elements');

  const onCellKeyDown = React.useCallback(
    (params, event) => {
      if (event.key === 'Enter') {
        onRowClick(params);
      }
    },
    [onRowClick]
  );

  const memoizedOnChangePage = React.useCallback(actions.onChangePage, [actions.onChangePage]);

  const memoizedOnSortChange = React.useCallback(actions.onColumnSortChange, [
    actions.onColumnSortChange
  ]);

  const memoizedOnChangePageSize = React.useCallback(actions.onChangeRowsPerPage, [
    actions.onChangeRowsPerPage
  ]);

  const getRowClassName = React.useCallback(
    (params) => {
      const cn = classNames({
        [classes.highlightedRow]: highlight.includes(params.row.id),
        [classes.clickableRow]: onRowClick
      });

      return cn;
    },
    [highlight, onRowClick, classes]
  );

  const handleSearch = React.useCallback(
    ({ target: { value } }) => {
      setSearch(value);

      clearTimeout(timeoutRef.current);

      timeoutRef.current = setTimeout(() => {
        actions.onSearchChange(value, true);
      }, 500);
    },
    [actions, setSearch]
  );

  const handleSortModelChange = React.useCallback(
    (newSortModel) => {
      setSortModel(newSortModel);
    },
    [setSortModel]
  );

  const handleColumnVisibilityModelChange = React.useCallback(
    (newModel) => {
      setColumnVisibilityModel(newModel);
      storage.setItem(COLUMN_VISIBILITY_STORAGE_KEY, JSON.stringify(newModel));
      onColumnVisibilityCallback(newModel);
    },
    [setColumnVisibilityModel, onColumnVisibilityCallback]
  );

  React.useEffect(() => {
    if (columnVisibilityModelProps) {
      setColumnVisibilityModel({
        ...JSON.parse(storage.getItem(COLUMN_VISIBILITY_STORAGE_KEY) || '{}'),
        ...columnVisibilityModelProps
      });
    }
  }, [columnVisibilityModelProps, setColumnVisibilityModel]);

  React.useEffect(() => {
    setRowCountState((prevRowCountState) => (count !== undefined ? count : prevRowCountState));
  }, [count, setRowCountState]);

  React.useEffect(() => {
    const sortField = sortModel.length ? sortModel[0].field : null;
    const sortDirection = sortModel.length ? sortModel[0].sort : null;

    if (sortModel.length && sortField && sort[sortField] !== sortDirection) {
      memoizedOnSortChange(sortModel[0].field, sortModel[0].sort, true, true);
    }
  }, [sortModel, memoizedOnSortChange, sort]);

  const renderRowCount = React.useCallback(() => {
    return (
      <div className={classes.rowCount}>
        {t('total')} {count}
      </div>
    );
  }, [classes, count, t]);

  const renderToolbar = React.useCallback(
    () => (
      <Toolbar
        classes={classes}
        t={t}
        search={search}
        handleSearch={handleSearch}
        actions={actions}
        controls={controls}
        CustomToolbar={CustomToolbar}
        filters={filters}
        data={rows}
        rowsSelected={rowsSelected}
        filterHandlers={filterHandlers}
      />
    ),
    [
      classes,
      t,
      search,
      handleSearch,
      actions,
      controls,
      CustomToolbar,
      filters,
      rowsSelected,
      rows,
      filterHandlers
    ]
  );

  const handleColumnWidthChange = React.useCallback(
    (params) => {
      const newWidth = {
        ...columnWidths,
        [params.colDef.field]: params.width
      };

      setColumnWidth(newWidth);
      storage.setItem('dataGrid.columnWidths', JSON.stringify(newWidth));
    },
    [columnWidths]
  );

  const slots = React.useMemo(
    () => ({
      loadingOverlay: LinearProgress,
      toolbar: hiddenMenu ? null : renderToolbar,
      columnMenu: CustomColumnMenu,
      headerFilterMenu: null,
      footer: pagination
        ? () => {
            return (
              <>
                {showRowCount && renderRowCount()}
                <CustomPagination
                  t={t}
                  page={page}
                  count={count}
                  pageSize={pageSize}
                  startPage={startPage}
                  onChangePage={memoizedOnChangePage}
                  onChangePageSize={memoizedOnChangePageSize}
                  showRowCount={showRowCount}
                />
              </>
            );
          }
        : () => <div />
    }),
    [
      renderToolbar,
      renderRowCount,
      t,
      page,
      count,
      pageSize,
      startPage,
      memoizedOnChangePage,
      memoizedOnChangePageSize,
      pagination,
      showRowCount,
      hiddenMenu
    ]
  );

  const sizedColumns = React.useMemo(() => {
    return columns.map((column) => {
      const { field } = column;
      const width = columnWidths[field];

      if (width) {
        return {
          ...column,
          width
        };
      }

      return column;
    });
  }, [columns, columnWidths]);

  const sx = React.useMemo(
    () => ({
      height: height,
      width: isMobile && hiddenMenu ? window.innerWidth - 36 : '100%'
    }),
    [isMobile, height, hiddenMenu]
  );

  const [tabOutline, setTabOutline] = React.useState(false);
  const [onGrid, setOnGrid] = React.useState(false);

  const columnsPanelSx = React.useMemo(
    () => ({
      columnsPanel: {
        sx: {
          '& .MuiDataGrid-panelFooter button:first-child': {
            display: !controls?.customizeColumns ? 'none' : 'flex'
          }
        }
      }
    }),
    [controls]
  );

  return (
    <Box
      sx={sx}
      onBlur={() => !onGrid && setTabOutline(false)}
      onMouseOver={() => {
        setTabOutline(true);
        setOnGrid(true);
      }}
      onMouseLeave={() => setOnGrid(false)}
    >
      <DataGridPremium
        experimentalFeatures={{
          ariaV7: true,
          lazyLoading: true
        }}
        dataGridRef={dataGridRef}
        rows={rows || []}
        columns={sizedColumns}
        loading={loading}
        rowCount={rowCountState}
        checkboxSelection={checkable}
        disableRowSelectionOnClick={checkable}
        keepNonExistentRowsSelected={keepNonExistentRowsSelected}
        onRowClick={onRowClick}
        getRowClassName={getRowClassName}
        isRowSelectable={actions.isRowSelectable}
        onRowSelectionModelChange={actions.onRowsSelect}
        localeText={{
          ...ukUA.components.MuiDataGrid.defaultProps.localeText,
          toolbarColumns: t('columns'),
          toolbarDensity: t('density'),
          ...localeText
        }}
        slots={slots}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        unstable_headerFilters={unstableHeaderFilters}
        disableColumnFilter={unstableHeaderFilters}
        onColumnWidthChange={handleColumnWidthChange}
        onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
        columnVisibilityModel={columnVisibilityModel}
        onCellKeyDown={onCellKeyDown}
        disableColumnReorder={true}
        sx={{
          border: 'none',
          borderRadius: 0,
          '& .MuiDataGrid-columnHeaders': {
            display: isMobile && hiddenMenu && 'none'
          },
          '& .MuiDataGrid-cell': {
            minWidth: previewAttach ? '89%' : '55%'
          },
          '& .MuiDataGrid-row': {
            maxWidth: previewAttach && '100%',
            minWidth: previewAttach && '100%'
          },
          '& .MuiDataGrid-virtualScrollerRenderZone': {
            maxWidth: previewAttach && '100%'
          }
        }}
        getRowId={getRowId}
        className={classNames({
          [classes.columnOutline]: tabOutline,
          [classes.withRowCount]: showRowCount
        })}
        slotProps={columnsPanelSx}
        disableColumnMenu={!controls?.customizeColumns}
      />
    </Box>
  );
};

DataGrid.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.array),
  columns: PropTypes.arrayOf(PropTypes.array),
  highlight: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
  actions: PropTypes.object,
  filters: PropTypes.object,
  filterHandlers: PropTypes.object,
  rowsPerPage: PropTypes.number,
  checkable: PropTypes.bool,
  controls: PropTypes.object,
  count: PropTypes.number,
  page: PropTypes.number,
  keepNonExistentRowsSelected: PropTypes.bool,
  sort: PropTypes.object,
  CustomToolbar: PropTypes.node,
  rowsSelected: PropTypes.array,
  unstableHeaderFilters: PropTypes.object,
  startPage: PropTypes.number,
  columnVisibilityModel: PropTypes.object,
  pagination: PropTypes.bool,
  showRowCount: PropTypes.bool,
  getRowId: PropTypes.func,
  onRowClick: PropTypes.func,
  height: PropTypes.string,
  onColumnVisibilityCallback: PropTypes.func,
  hiddenMenu: PropTypes.bool,
  localeText: PropTypes.object
};

DataGrid.defaultProps = {
  rows: [],
  columns: [],
  highlight: [],
  loading: false,
  actions: {},
  filters: {},
  checkable: false,
  controls: {},
  count: 0,
  page: 1,
  rowsPerPage: 10,
  keepNonExistentRowsSelected: true,
  sort: {},
  CustomToolbar: null,
  rowsSelected: [],
  filterHandlers: null,
  unstableHeaderFilters: null,
  startPage: null,
  columnVisibilityModel: {},
  pagination: true,
  showRowCount: false,
  getRowId: null,
  onRowClick: () => {},
  height: '100%',
  onColumnVisibilityCallback: () => {},
  hiddenMenu: false,
  localeText: {}
};

export default DataGrid;
