import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-translate';
import { connect } from 'react-redux';

import {
  Typography,
  Toolbar,
  Button,
  Hidden,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import RightSidebarLayout from 'layouts/RightSidebarLayout';
import { Content } from 'layouts/LeftSidebar';

import ModulePage from 'components/ModulePage';

import {
  SchemaForm,
  SchemaStepper,
  handleChangeAdapter,
  validateData
} from 'components/JsonSchema';

import capitalizeFirstLetter from 'helpers/capitalizeFirstLetter';

import SuccessMesssageLayout from 'modules/tasks/pages/Task/components/SuccessMessageLayout';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import profileEmailSchema from './variables/profileEmailSchema.json';

const schemas = {
  email: profileEmailSchema
};

const styles = (theme) => ({
  title: {
    paddingTop: 0,
    fontSize: 56,
    fontWeight: 'normal',
    lineHeight: '60px',
    letterSpacing: '-0.02em',
    paddingBottom: 72,
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      fontSize: 26,
      lineHeight: '26px',
      marginTop: 8,
      paddingBottom: 30
    }
  },
  schemaTitle: {
    padding: 0,
    marginBottom: 35,
    fontSize: 38,
    lineHeight: '40px',
    letterSpacing: '-0.02em',
    maxWidth: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
      lineHeight: '24px',
      marginBottom: 20,
      maxWidth: '80%'
    }
  },
  toolbar: {
    margin: 0,
    padding: '24px 0'
  },
  button: {
    marginRight: 16
  },
  attention: {
    marginTop: 5,
    marginBottom: 5
  },
  attentionText: {
    marginTop: 5,
    marginBottom: 50
  },
  detailsRoot: {
    [theme.breakpoints.down('lg')]: {
      display: 'block'
    }
  },
  accordion: {
    marginBottom: '40px!important'
  }
});

class ContactFormPage extends ModulePage {
  constructor(props) {
    super(props);

    const { userInfo } = props;

    this.state = {
      errors: [],
      value: userInfo
    };
  }

  componentDidUpdate({ type: prevType, userInfo: prevUserInfo }) {
    const { type, userInfo } = this.props;

    if (prevUserInfo[prevType] !== userInfo[type]) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        errors: [],
        value: userInfo
      });
    }
  }

  componentGetTitle() {
    const { t, type } = this.props;
    return t('Profile' + capitalizeFirstLetter(type));
  }

  getActiveStep = () => {
    const { value } = this.state;
    const { type } = this.props;
    return value.confirmation && type === 'email' ? 'confirmation' : type;
  };

  handleNextStep = () => {
    const { type } = this.props;
    const { value } = this.state;

    const activeStep = this.getActiveStep();
    const schema = schemas[type][activeStep];

    const errors = validateData(value, schema);

    if (errors && errors.length) {
      this.setState({ errors });
      return;
    }

    this.setState({ errors: [], value: { ...value, confirmation: {} } });
  };

  handlePrevStep = () => {
    const {
      value: { confirmation, ...value }
    } = this.state;
    this.setState({ value });
  };

  handleChange = (value) => this.setState({ value });

  renderFinalScreen() {
    const { t, type } = this.props;
    const { value } = this.state;

    return (
      <SuccessMesssageLayout
        finalScreen={{
          title: t('Profile' + capitalizeFirstLetter(type) + 'SuccessTitle'),
          subtitle: t('Profile' + capitalizeFirstLetter(type) + 'SuccessSubTitle', {
            email: value?.email
          }),
          actions: [
            {
              title: t('Done'),
              link: '/profile',
              color: 'primary',
              variant: 'contained'
            }
          ]
        }}
      />
    );
  }

  render() {
    const { t, type, classes, loading, location } = this.props;

    const { value, errors } = this.state;

    if (value.confirmation && value.confirmation.confirmed) {
      return this.renderFinalScreen();
    }

    const steps = [type];

    if(type === 'email') {
      steps.push('confirmation');
    }
    const title = this.componentGetTitle();
    const activeStep = this.getActiveStep();

    return (
      <RightSidebarLayout
        backLink="/profile"
        title={title}
        location={location}
        loading={loading}
        details={
          <>
            <Typography variant="h5" className={classes.attention}>
              {t(capitalizeFirstLetter(type) + 'Attention')}
            </Typography>
            <Typography variant="body1" className={classes.attentionText}>
              {t(capitalizeFirstLetter(type) + 'AttentionText')}
            </Typography>
          </>
        }
      >
        <Content>
          <Typography variant="h4" className={classes.title}>
            {title}
          </Typography>
          <SchemaStepper steps={steps} activeStep={steps.indexOf(activeStep)} />
          <Typography variant="h4" className={classes.schemaTitle}>
            {t(
              [
                capitalizeFirstLetter(type),
                steps.indexOf(activeStep) ? 'Confirmation' : 'Typing',
                'Step'
              ].join('')
            )}
          </Typography>
          <Hidden lgUp={true} implementation="css">
            <Accordion className={classes.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {t(capitalizeFirstLetter(type) + 'Attention')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                classes={{
                  root: classes.detailsRoot
                }}
              >
                <Typography>{t(capitalizeFirstLetter(type) + 'AttentionText')}</Typography>
              </AccordionDetails>
            </Accordion>
          </Hidden>
          <SchemaForm
            schema={schemas[type][activeStep]}
            errors={errors}
            value={value}
            onChange={handleChangeAdapter(value, this.handleChange)}
            handleNextStep={this.handleFinish}
            rootDocument={{ data: value }}
          />
          <Toolbar className={classes.toolbar}>
            {steps.indexOf(activeStep) ? (
              <Button
                size="large"
                variant="outlined"
                className={classes.button}
                onClick={this.handlePrevStep}
                id="prev-step-button"
              >
                {t('PrevStepBtn')}
              </Button>
            ) : null}
            {steps.indexOf(activeStep) === 0 ? (
              <Button
                size="large"
                color="primary"
                variant="contained"
                className={classes.button}
                onClick={this.handleNextStep}
                id="next-step-button"
              >
                {t('NextStepBtn')}
              </Button>
            ) : null}
          </Toolbar>
        </Content>
      </RightSidebarLayout>
    );
  }
}

ContactFormPage.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  userInfo: PropTypes.object.isRequired,
  type: PropTypes.string
};

ContactFormPage.defaultProps = {
  type: 'email'
};

const mapStateToProps = ({ auth: { info } }) => ({ userInfo: info });

const styled = withStyles(styles)(ContactFormPage);
const translated = translate('ContactFormPage')(styled);
export default connect(mapStateToProps)(translated);
