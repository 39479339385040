import React, { Suspense, lazy } from 'react';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import Preloader from 'components/Preloader';
import PdfDocument from 'components/FilePreview/components/PdfDocument';
import CodeDocument from 'components/FilePreview/components/CodeDocument';
import UnsupportedComponent from 'components/FilePreview/components/UnsupportedComponent';

const XlsxViewer = lazy(() => import('components/FilePreview/components/xslx/xslx'));
const FileViewer = lazy(() => import('react-file-viewer'));

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    backgroundColor: '#ffffff',
    [theme.breakpoints.down('xl')]: {
      left: 'unset'
    },
    '& .rpv-core__viewer > div > div': {
      overflow: 'inherit!important'
    }
  },
  toolbar: {
    position: 'sticky',
    bottom: 0,
    padding: '0 10px'
  },
  unsupportedContainer: {
    padding: 40
  },
  printIcon: {
    position: 'absolute',
    bottom: 65,
    left: 10,
    [theme.breakpoints.down('xl')]: {
      display: 'none'
    }
  }
}));

const FilePreview = ({ file, fileType, darkTheme, customToolbar, open, withPrint }) => {
  const [error, setError] = React.useState(false);
  const [imageSizeNotPowerOfTwo, setImageSizeNotPowerOfTwo] = React.useState(false);
  const classes = useStyles();
  const formatsToCheck = ['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(fileType);

  const isPowerOfTwo = (number) => (number & (number - 1)) === 0;

  React.useEffect(() => {
    if (formatsToCheck) {
      const img = new Image();
      img.src = file;
      img.onload = () => {
        if (!isPowerOfTwo(img.width) || !isPowerOfTwo(img.height)) {
          setImageSizeNotPowerOfTwo(true);
        }
      };
      img.onerror = () => {
        setError(true);
      };
    }
  }, [file, fileType, formatsToCheck]);

  const checkImageRenderError = React.useCallback(() => {
    if (!formatsToCheck) return null;

    return <img src={file} hidden={true} alt="" onError={setError} />;
  }, [file, formatsToCheck]);

  return (
    <div className={classNames(classes.root, classes[fileType])}>
      {fileType === 'pdf' ? (
        <PdfDocument
          file={file}
          darkTheme={darkTheme}
          customToolbar={customToolbar}
          open={open}
          withPrint={withPrint}
        />
      ) : fileType === 'xlsx' ? (
        <XlsxViewer filePath={file} fileType={fileType} darkTheme={darkTheme} />
      ) : ['json', 'bpmn'].includes(fileType) ? (
        <CodeDocument file={file} fileType={fileType} />
      ) : (
        <Suspense fallback={<Preloader flex={true} />}>
          {error ? (
            <div className="unsupported-message">
              <UnsupportedComponent />
            </div>
          ) : imageSizeNotPowerOfTwo ? (
            <img src={file} alt="" style={{ maxWidth: '100%' }} />
          ) : (
            <FileViewer
              darkTheme={darkTheme}
              filePath={file}
              fileType={fileType}
              unsupportedComponent={UnsupportedComponent}
            />
          )}
          {checkImageRenderError()}
        </Suspense>
      )}
      <span />
    </div>
  );
};

export default FilePreview;
