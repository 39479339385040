/* eslint-disable react/prop-types */
import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { translate } from 'react-translate';
import diiaLogo from 'assets/img/diia-logo.svg';

const styles = (theme) => ({
  logo: {
    width: 48,
    height: 48,
    display: 'block',
    backgroundSize: 'cover',
    [theme.breakpoints.down('md')]: {
      width: 32,
      height: 32
    },
    '&:nth-child(2)': {
      marginLeft: 8
    }
  },
  link: {
    display: 'flex'
  },
  logoWrap: {
    display: 'flex',
    alignItems: 'center'
  },
  logoText: {
    flex: 1,
    marginLeft: '6px',
    [theme.breakpoints.down('md')]: {
      fontSize: '14px'
    },
  }
});

const LogoLinks = ({ t, classes }) => (
  <div className={classes.logoWrap}>
      <span className={classes.logo} style={{ backgroundImage: `url(${diiaLogo})` }} />
      <span className={classes.logoText}>Міністерство у справах ветеранів України</span>
  </div>
);

const styled = withStyles(styles)(LogoLinks);
export default translate('Layout')(styled);
