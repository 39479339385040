/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useTranslate } from 'react-translate';
import { pdfjs, Document, Page } from 'react-pdf';
import Pagination from '@mui/material/Pagination';
import { Button, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import config from 'config';

if (config?.pdfWorkerLocal) {
  pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'js/pdfjs/pdf.worker.min.js',
    `${window.location.origin}`
  ).toString();
} else {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto'
  },
  wrapper: {
    overflow: 'auto',
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    '& > *': {
      margin: 'auto'
    }
  },
  paginationWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    paddingBottom: 40
  },
  pagination: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: 40,
    [theme.breakpoints.down('lg')]: {
      display: 'none'
    }
  },
  zoom: {
    margin: 'auto',
    marginBottom: 20,
    paddingTop: 3
  },
  darkTheme: {
    background: '#404040'
  },
  pdfDocument: {
    marginBottom: 20
  },
  zoomButton: {
    color: '#000',
    '&.Mui-disabled': {
      color: '#767676'
    }
  },
  buttonRoot: {
    ...(theme.pdfPaginationActions || {})
  }
}));

const WIDTH_STEP = 250;
const MAX_ZOOM = 3500;
const MIN_ZOOM = 600;

const PdfDocument = ({ file, darkTheme }) => {
  const t = useTranslate('TaskPage');

  const wrapperRef = React.useRef();

  const classes = useStyles();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const [width, setWidth] = React.useState(720);

  React.useEffect(() => {
    if (!wrapperRef.current || config?.variables?.pdfPreviewUseZoom) {
      return;
    }

    setWidth(wrapperRef.current.clientWidth);
  }, [wrapperRef]);

  const buttonAttributes = React.useMemo(
    () =>
      darkTheme
        ? {
            variant: 'outlined',
            color: 'primary'
          }
        : {},
    [darkTheme]
  );

  return (
    <>
      <div className={classes.root}>
        <div className={classes.wrapper} ref={wrapperRef}>
          {config?.variables?.pdfPreviewUseZoom ? (
            <div className={classes.zoom} style={{ width }}>
              <IconButton
                disabled={width > MAX_ZOOM}
                onClick={() => setWidth(width + WIDTH_STEP)}
                aria-label={t('PdfPreviewPlus')}
                className={classes.zoomButton}
              >
                <AddIcon />
              </IconButton>
              <IconButton
                disabled={width < MIN_ZOOM}
                onClick={() => setWidth(width - WIDTH_STEP)}
                aria-label={t('PdfPreviewMinus')}
                className={classes.zoomButton}
              >
                <RemoveIcon />
              </IconButton>
            </div>
          ) : null}

          <div tabIndex="0" className={classes.pdfDocument}>
            <Document
              file={file}
              loading={t('Loading')}
              noData={t('NoData')}
              onLoadSuccess={({ numPages }) => setNumPages(numPages)}
            >
              <Page pageNumber={pageNumber} width={width} tabIndex="0" />
            </Document>
          </div>

          {numPages && numPages > 1 ? (
            <div
              className={classNames({
                [classes.paginationWrapper]: true,
                [classes.darkTheme]: darkTheme
              })}
              style={{ width }}
            >
              <Button
                className={classNames('pagination-action')}
                classes={{
                  root: classes.buttonRoot
                }}
                disabled={pageNumber === 1}
                startIcon={<KeyboardArrowLeftIcon />}
                onClick={() => setPageNumber(pageNumber - 1)}
                aria-label={t('PreviousPage')}
                {...buttonAttributes}
              >
                {t('PreviousPage')}
              </Button>
              <Pagination
                page={pageNumber}
                count={numPages}
                {...buttonAttributes}
                hidePrevButton={true}
                hideNextButton={true}
                getItemAriaLabel={(page, pageNumber) => t('Page', { page: pageNumber })}
                className={classes.pagination}
                onChange={(e, page) => setPageNumber(page)}
              />
              <Button
                className={classNames('pagination-action')}
                classes={{
                  root: classes.buttonRoot
                }}
                disabled={pageNumber === numPages}
                endIcon={<KeyboardArrowRightIcon />}
                onClick={() => setPageNumber(pageNumber + 1)}
                aria-label={t('NextPage')}
                {...buttonAttributes}
              >
                {t('NextPage')}
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default PdfDocument;
