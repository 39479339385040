export default (t, residentUserData) =>  ({
    type: 'object',
    properties: {
      personalData: {
        control: 'text.block',
        htmlBlock: `<p style='font-size: 28px; line-height: 32px; color: #000;'>${t(
          'Personal'
        )}</p>`
      },
      about: {
        control: 'text.block',
        htmlBlock: `<div style='margin-top: 40px; margin-bottom:60px; padding: 2px; position: relative; animation: 10s infinite granimate; transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; box-shadow: none; background-size: 200% 300%; background-image: linear-gradient(217deg,rgba(255,0,0,.8),rgba(255,0,0,0) 70.71%),linear-gradient(127deg,rgba(0,0,255,.8),rgba(0,0,255,0) 70.71%),linear-gradient(336deg,rgba(0,255,0,.8),rgba(0,255,0,0) 70.71%);'><div style='padding: 26px 32px; background: #fff;'><p style='font-size:18px; line-height:28px;'>
        ${
          residentUserData
            ? `
              ${residentUserData?.surname}
              ${residentUserData?.givenName}
              ${residentUserData?.patronymic}
            `
            : '{{userName}}'
        }
        </p><div style='display:inline-flex;'><div style='display:block; margin-right:50px;'><p style='font-size: 12px; line-height:16px; opacity: 0.5; margin-bottom:5px;'>${t(
          'Ipn'
        )}</p><p style='margin-top:5px;'>{{ipn}}</p></div></div></div></div>`,
        params: {
          userName: 'name',
          ipn: 'cyrillicIpnPassport'
        }
      },
      email: {
        control: 'text.block',
        row: true,
        htmlBlock: `
          <p class='headline'>${t('Email')}</p>
          <div style='display:flex;'><p style='margin-top:5px; margin-right:10px;'>{{email}}</p>
          ${
            !residentUserData
              ? `<a href='/profile/email' title='Редагувати' class='editIconLink'>
                <img src='img/ic_edit.svg' alt='Edit' />
              </a>`
              : ''
          }
          </div></p>`,
        params: {
          email: 'email'
        }
      }
    },
    required: []
  });
