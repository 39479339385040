/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect, useDispatch } from 'react-redux';
import { Modal, AppBar, Toolbar, IconButton, Divider, Dialog, Slide, Paper } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import CloseIcon from '@mui/icons-material/Close';
import userIsResident from 'helpers/userIsResident';
import { getResidentData } from 'actions/eResident';
import UserMenu from './UserMenu';
import LogoLinks from '../components/LogoLinks';
import CongratMessage from './CongratMessage';
import TabMenu from './TabMenu';

const styles = (theme) => ({
  header: {
    padding: 0,
    background: '#E7EEF3',
    color: '#000'
  },
  flexGrow: {
    flexGrow: 1
  },
  toolbar: {
    left: 0,
    right: 0,
    position: 'absolute',
    padding: '16px 32px',
    alignItems: 'center',
    minHeight: 'auto',
    zIndex: 1,
    '& > *': {
      marginRight: 8
    },
    '& > *:last-child': {
      marginRight: 0
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 7,
      paddingBottom: 7,
      borderBottom: '2px solid #000',
      marginLeft: 16,
      marginRight: 16,
      alignItems: 'center'
    }
  },
  topMenu: {
    display: 'flex',
    flex: 1,
    padding: '12px 32px',
    flexGrow: 1,
    alignItems: 'center'
  },
  dawerPaper: {
    color: '#ffffff'
  },
  dawerMenu: {
    padding: '12px 4px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      padding: 0,
      paddingTop: 20
    }
  },
  BurgerItem: {
    display: 'block',
    width: 16,
    height: 2,
    marginBottom: 3,
    position: 'relative',
    background: '#000'
  },
  bottomBurgerItem: {
    marginBottom: 0
  },
  modalContainer: {
    maxWidth: 1680,
    margin: 'auto'
  },
  desktopScreen: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  mobileScreen: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block'
    }
  },
  paperFullScreen: {
    padding: 16,
    paddingTop: 0,
    background: '#E7EEF3'
  },
  modalToolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 0
  },
  modalToolbarLinks: {
    display: 'flex',
    '& > a': {
      marginRight: 6
    }
  },
  closeDialogBtn: {
    marginRight: -10
  }
});

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const Header = ({ classes, location, tabMenuLinks, ipn }) => {
  const [userData, setUserData] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [openServiceMenu, setOpenServiceMenu] = React.useState(false);


  const [loading, setLoading] = React.useState(false);

  const dispatch = useDispatch();

  React.useEffect(() => {
    const resident = userIsResident();

    if (!resident) return;

    const fetchData = async () => {
      setLoading(true);

      const record = await getResidentData()(dispatch);

      const { givenName, surname } = record;

      setLoading(false);

      if (!record) return;

      setUserData({
        givenName,
        surname
      });
    };

    fetchData();
  }, [dispatch, ipn]);

  if (loading) return null;

  const CloseButton = () => (
    <IconButton onClick={() => setOpen(false)} className={classes.closeDialogBtn} size="large">
      <CloseIcon style={{ color: '#000' }} />
    </IconButton>
  );

  const header = (
    <AppBar className={classes.header} position="relative" elevation={0}>
      <div className={classes.desktopScreen}>
        <Toolbar className={classes.toolbar}>
          <LogoLinks />
          <div className={classes.topMenu}></div>
          <UserMenu userData={userData} />
        </Toolbar>
      </div>

      <div className={classes.mobileScreen}>
        <Toolbar className={classes.toolbar}>
          <LogoLinks />
          <div className={classes.flexGrow} />
          <IconButton onClick={() => setOpen(true)} aria-label="menu" size="large">
            <div>
              <span className={classes.BurgerItem} />
              <span className={classes.BurgerItem} />
              <span className={classNames(classes.BurgerItem, classes.bottomBurgerItem)} />
            </div>
          </IconButton>
        </Toolbar>

        <Dialog
          fullScreen={true}
          open={open}
          TransitionComponent={Transition}
          classes={{ paperFullScreen: classes.paperFullScreen }}
        >
          <Toolbar className={classes.modalToolbar}>
            <div className={classes.modalToolbarLinks}>
              <LogoLinks />
            </div>
            <CloseButton />
          </Toolbar>
          <Divider />
          <div className={classes.dawerMenu}></div>
          <UserMenu userData={userData} />
        </Dialog>
      </div>
      <CongratMessage userData={userData} />
      <TabMenu location={location} tabMenuLinks={tabMenuLinks} />
    </AppBar>
  );

  return (
    <>
      {header}
      <Modal
        open={openServiceMenu}
        disableAutoFocus={true}
        disableEnforceFocus={false}
        onClose={() => setOpenServiceMenu(false)}
      >
        <Paper elevation={0} className={classes.modalContainer}>
          {header}
        </Paper>
      </Modal>
    </>
  );
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

const mapStateToProps = ({
  auth: {
    info: { ipn }
  }
}) => ({
  ipn
});

const mapDispatchToProps = () => ({ actions: {} });

const styled = withStyles(styles)(Header);

export default connect(mapStateToProps, mapDispatchToProps)(styled);
